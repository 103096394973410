import React from 'react';
import {
    Button, Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select, TextField, Tooltip, Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import axios from "axios";
import Decimal from "decimal.js";

class Database extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {

            creating: {
                db: "mysql",
                storageSize: 10,
            },
        }

        this.updateCreating.bind(this)
    }


    updateCreating(name, value) {

        let creating = this.state.creating
        creating[name] = value
        this.setState({creating: creating})
    }

    componentDidMount() {

    }

    render() {
        const {t} = this.props;

        //day -> 86400
        //month (30 day) -> 2592000

        //AWS S3 Standard 0.8 TWD / GB / month
        let MariaDB = new Decimal("0.0000011574");

        let price = MariaDB.times(this.state.creating.storageSize || 0);

        price = price.times(86400)
        // price = price.toFixed(2)
        return (
            <>
                <div style={{width: "100%", maxWidth: 360}}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel>{t('Location')}</FormLabel>
                        <Select displayEmpty fullWidth disabled
                                value={"1"}
                                onChange={(e) => {

                                }}
                        >
                            {/*<Divider textAlign={"left"}>亞洲</Divider>*/}
                            <MenuItem value={"1"}>
                                <div style={{
                                    //512 x 340
                                    display: "inline-block",
                                    backgroundImage: "url(https://account.zentring.net/static/media/TW.dc169005.svg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    borderRadius: 5,
                                    width: 25,
                                    backgroundPositionY: -4.19921875,
                                    height: 16.6015625,
                                    verticalAlign: "middle",
                                    marginRight: 10,
                                }}/>
                                {t('Republic of China')} - {t('Kaohsiung')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{height: 20}}/>

                <div style={{width: "100%", maxWidth: 360}}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel>資料庫</FormLabel>
                        <Select displayEmpty style={{marginRight: 1}}
                                value={this.state.creating.db}
                                onChange={(e) => {
                                    this.updateCreating("db", e.target.value)
                                }}
                        >
                            <MenuItem value={"mysql"}> MySQL </MenuItem>
                            <MenuItem value={"mongodb"}>MongoDB</MenuItem>
                            <MenuItem value={"postgresql"}>PostgreSQL</MenuItem>
                            <MenuItem value={"mariadb"}>MariaDB</MenuItem>
                            {/*<MenuItem value={"redis"}>Redis</MenuItem>*/}
                            {/*<MenuItem value={"memcached"}>Memcached</MenuItem>*/}
                        </Select>
                    </FormControl>
                </div>
                <div style={{height: 30}}/>
                <div style={{width: "100%", maxWidth: 360}}>
                    <FormControl fullWidth>
                        <FormLabel>{t('Capacity')}</FormLabel>
                        <TextField variant="standard"
                                   sx={{width: "100%", maxWidth: 360}}
                                   value={this.state.creating.storageSize}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">GB</InputAdornment>,
                                   }}
                                   inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                   onChange={(e) => {
                                       if (isNaN(Number(e.target.value))) {
                                           return
                                       }
                                       this.updateCreating("storageSize", e.target.value)
                                   }}/>
                    </FormControl>

                </div>
                <div style={{height: 30}}/>

                <div style={{width: "100%", maxWidth: 360}}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        註：以上價格以每個月經常存取且用量普通估算，實際費用計算為浮動費率
                    </Typography>

                    <Typography variant={"h6"}>
                        {t('Estimated costs')}：NT${(this.props.isInDay ? price : price * 30).toFixed(2)} / {this.props.isInDay ? t('day') : t('month')}
                    </Typography>

                    <div style={{height: 30}}/>
                    <Tooltip title={"尚未開放"}>
                        <span>
                            <Button variant={"contained"} color={"primary"} onClick={(e) => {
                                this.props.onItemAdd(this.state.creating, price)
                            }}>新增</Button>
                        </span>
                    </Tooltip>
                </div>
            </>
        );
    }
}

export default withTranslation()(Database);