import React from 'react';
import {
    Button, Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select, TextField, Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import axios from "axios";
import Decimal from "decimal.js";

class Storage extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {

            creating: {
                storageType: "ssd",
                storageSize: 10,
            },
        }

        this.updateCreating.bind(this)
    }


    updateCreating(name, value) {

        let creating = this.state.creating
        creating[name] = value
        this.setState({creating: creating})
    }

    componentDidMount() {

    }

    render() {
        const {t} = this.props;

        //day -> 86400
        //month (30 day) -> 2592000
        let SSD = new Decimal("0.000001929");
        let HDD = new Decimal("0.0000007716");

        let price = (this.state.creating.storageType === "ssd" ? SSD : HDD).times(this.state.creating.storageSize || 0);

        price = price.times(86400)
        // price = price.toFixed(2)
        return (
            <>
                <Collapse in={true}>
                    <div style={{width: "100%", maxWidth: 360}}>
                        <FormControl variant="standard" fullWidth>
                            <FormLabel>{t('Location')}</FormLabel>
                            <Select displayEmpty fullWidth disabled
                                    value={"1"}
                                    onChange={(e) => {

                                    }}
                            >
                                {/*<Divider textAlign={"left"}>亞洲</Divider>*/}
                                <MenuItem value={"1"}>
                                    <div style={{
                                        //512 x 340
                                        display: "inline-block",
                                        backgroundImage: "url(https://account.zentring.net/static/media/TW.dc169005.svg)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        borderRadius: 5,
                                        width: 25,
                                        backgroundPositionY: -4.19921875,
                                        height: 16.6015625,
                                        verticalAlign: "middle",
                                        marginRight: 10,
                                    }}/>
                                    {t('Republic of China')} - {t('Kaohsiung')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{height: 20}}/>

                    <div style={{width: "100%", maxWidth: 360}}>
                        <FormControl>
                            <FormLabel>硬碟</FormLabel>
                            <RadioGroup row value={this.state.creating.storageType}
                                        onChange={(e) => {
                                            this.updateCreating("storageType", e.target.value)
                                        }}>
                                <FormControlLabel value="ssd" control={<Radio/>} label="SSD"/>
                                <FormControlLabel value="hdd" control={<Radio/>} label="HDD"/>
                            </RadioGroup>
                        </FormControl>

                        <FormControl fullWidth>
                            <FormLabel>硬碟容量</FormLabel>
                            <TextField variant="standard" label={t('Capacity')}
                                       sx={{width: "100%", maxWidth: 360}}
                                       value={this.state.creating.storageSize}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end">GB</InputAdornment>,
                                       }}
                                       inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                       onChange={(e) => {
                                           if (isNaN(Number(e.target.value))) {
                                               return
                                           }
                                           this.updateCreating("storageSize", e.target.value)
                                       }}/>
                        </FormControl>

                    </div>
                    <div style={{height: 30}}/>

                    <Typography
                        variant={"h6"}>{t('Estimated costs')}：NT${(this.props.isInDay ? price : price * 30).toFixed(2)} / {this.props.isInDay ? t('day') : t('month')}</Typography>

                    <div style={{height: 30}}/>
                    <Button variant={"contained"} color={"primary"} onClick={(e) => {
                        this.props.onItemAdd(this.state.creating, price)
                    }}>新增</Button>
                </Collapse>
            </>
        );
    }
}

export default withTranslation()(Storage);