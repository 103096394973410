import {Box, Container, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import React from "react";
import {ScrollRestoration} from "react-router-dom";

export default function Terms() {

    const date = "2024/06/28"

    return <>
        <ScrollRestoration/>
        <div style={{overflow: "hidden", background: "white", minHeight: "calc(100VH - 500px)"}}>
            <div style={{position: "relative", background: "#ffffff",}}>
                <Container>
                    <Typography variant={"h4"} align={"left"} style={{paddingTop: 20}}>
                        服務條款
                    </Typography>
                    <Typography variant={"body1"} align={"left"} color={"textSecondary"}>
                        <Trans i18nKey="Last updated on">
                            Last updated on {date}
                        </Trans>
                    </Typography>


                    <Box sx={{mt: 1}}/>

                    <Typography variant={"body1"}>
                        此條款作為 <a href={"https://zentring.net/tos"}>基本服務條款</a> 的補充。
                    </Typography>

                    <Typography variant={"body1"}>
                        此條款僅適用於帳戶地區設置為 中華民國 之情事。
                    </Typography>

                    <Box sx={{mt: 4}}/>


                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        帳戶資料
                    </Typography>

                    <Box sx={{ml: 4}}>

                        <Typography variant={"body1"}>
                            初次使用 Zentring Cloud 服務時，系統會建立預設群組帳戶，並且會自 Zentring
                            帳戶中取得您的部分基本資料（包含但不限於姓名、聯絡電話及付款方式等資訊）。
                        </Typography>

                        <Typography variant={"body1"}>
                            帳戶分為兩種運作方式，其運作方式如下所述：
                        </Typography>

                        <ol>
                            <li>預付型：於租用服務前預先儲值一定金額至帳戶，並使用該帳戶進行扣款</li>
                            <li>結算型：設定好每月扣繳日及付款方式，自動進行扣款</li>
                        </ol>
                    </Box>

                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        預付型帳戶
                    </Typography>

                    <Box sx={{ml: 4}}>

                        <Typography variant={"body1"}>
                            預付型帳戶為預設選項，於帳戶餘額不足或即將不足時，系統會自動停止服務。
                        </Typography>
                        <Box sx={{ml: 2, mb: 2}}>
                            <Typography variant={"body2"}>
                                註1：虛擬機器停止時間會預留儲存空間費用 72
                                小時，該筆費用會被圈存，於設定中選擇不圈存除外（若選擇不圈存，於帳戶餘額用磬後，硬碟將自動刪除）。
                            </Typography>
                            <Typography variant={"body2"}>
                                註2：虛擬機器於停止時並非傳送 ACPI 通知，因此可能造成資料損失。
                            </Typography>
                            <Typography variant={"body2"}>
                                註3：資料庫和網站託管服務於停止時，資料將保留 7 天，逾期將自動刪除。
                            </Typography>
                            <Typography variant={"body2"}>
                                註4：容器服務於停止時，使用者掛載的資料將保留 7 天，逾期將自動刪除（自行上傳的映像不受影響）。
                            </Typography>
                        </Box>
                        <Typography variant={"body1"}>
                            預付型帳戶可用以下方式付款：
                        </Typography>
                        <ul>
                            <li>信用卡/簽帳卡/禮物卡：VISA、MasterCard、JCB</li>
                            <li>銀行轉帳：虛擬繳款帳號</li>
                            <li>超商條碼繳費：7-11</li>
                        </ul>

                        <Typography variant={"body1"}>
                            帳戶餘額低於 48 小時服務費用時，系統會寄送電子郵件提醒；帳戶餘額低於 24 小時服務費用時，會寄送電子郵件及簡訊提醒。
                        </Typography>
                        <Typography variant={"body1"}>
                            已停止的服務於帳戶餘額補足後，系統會自動恢復服務。
                        </Typography>
                    </Box>

                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        結算型帳戶
                    </Typography>

                    <Box sx={{ml: 4}}>

                        <Typography variant={"body1"}>
                            結算型帳戶需提出申請並驗證身份（依各種實體類型）後方可使用
                        </Typography>
                        <ol>
                            <li>自然人：驗證本人身份證</li>
                            <li>法人：驗證負責人的身份證及設立核准函</li>
                        </ol>

                        <Typography variant={"body1"}>
                            結算型帳戶可用以下方式付款：
                        </Typography>
                        <ul>
                            <li>信用卡/簽帳卡/禮物卡：VISA、MasterCard、JCB</li>
                            <li>銀行轉帳：虛擬繳款帳號</li>
                            <li>超商條碼繳費：7-11</li>
                        </ul>
                        <Typography variant={"body1"}>
                            於每月指定付款日起 7 天，使用者可於 Console 進行帳單查詢完成付款，或於第 7 天自動從已設定的信用卡扣款。
                        </Typography>
                        <Box sx={{ml: 2, mb: 2}}>
                            <Typography variant={"body2"}>
                                註：自動扣款所設定的付款方式限信用卡，無法使用 禮物卡、預付卡、簽帳金融卡等卡別。
                            </Typography>
                        </Box>
                    </Box>

                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        服務訂閱
                    </Typography>

                    <Box sx={{ml: 4}}>
                        <Typography variant={"body1"}>
                            除特定項目外，所有服務皆可隨租隨用
                        </Typography>
                        <Typography variant={"body1"}>
                            各服務於租用或訂閱時，會於 Console 中顯示詳細資訊，確認建立後則視為已同意且開始計費。
                        </Typography>
                    </Box>


                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        實體設備
                    </Typography>

                    <Box sx={{ml: 4}}>

                        <Typography variant={"body1"}>
                            此條目適用訂閱服務含有實體設備之情事
                        </Typography>

                        <Box sx={{mt: 2}}/>

                        <Typography variant={"body1"}>
                            若訂閱的服務含有實體設備且訂閱時需置於本公司機房 （如 <b>計算 > 實體主機</b> 所需之伺服器），
                            依照性質不同有下列處理方式。
                        </Typography>

                        <ol>
                            <li>自備設備：待租用流程完成後，親送或郵寄設備至本公司，將有專人與您一同處理後續相關事宜。</li>
                            <li>本公司代為採購：於服務租用合約確認後支付設備款項，始得進行後續流程。</li>
                        </ol>

                        <Box sx={{mt: 5}}/>

                        <Typography variant={"body1"}>
                            若訂閱的服務需將實體設備置於客戶指定地點
                            （如 <b>智慧生活 > 車牌辨識</b> 的終端設備），
                            則依照性質不同有下列處置方式。
                        </Typography>
                        <Box sx={{ml: 2, mb: 2}}>
                            <Typography variant={"body2"}>
                                註：此處僅限 Zentring Cloud 的軟體服務，關於本公司販售的實體商品請參考
                                <a href={"https://store.zentring.net/terms"}
                                   style={{marginLeft: 4, marginRight: 4}}>商店</a>
                                的相關條款。
                            </Typography>
                        </Box>

                        <ol>
                            <li>自備設備：需依照該服務的說明自行串接設置，並於第一次使用服務後開始計費（依各服務說明為準）。</li>
                        </ol>

                    </Box>

                    <Box sx={{mt: 5}}/>

                    <Typography variant={"body2"}>
                        天日科技有限公司<br/>
                        Zentring LTD.<br/>
                        {date}
                    </Typography>
                </Container>
            </div>

        </div>

    </>
}