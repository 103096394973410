import {Box, Container, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import React from "react";
import {ScrollRestoration} from "react-router-dom";

export default function Refund() {

    return <>
        <ScrollRestoration/>
        <div style={{overflow: "hidden", background: "white", minHeight: "calc(100VH - 500px)"}}>
            <div style={{position: "relative", background: "#ffffff",}}>
                <Container>
                    <Typography variant={"h4"} align={"left"} style={{paddingTop: 20}}>
                        退換貨政策
                    </Typography>
                    <Typography variant={"body1"} align={"left"} color={"textSecondary"}>
                        <Trans i18nKey="Last updated on">
                            Last updated on {"2024/06/28"}
                        </Trans>
                    </Typography>


                    <Box sx={{mt: 1}}/>

                    <Typography variant={"body1"}>
                        此條款僅適用於 Zentring Cloud 服務。
                    </Typography>

                    <Typography variant={"body1"}>
                        此條款僅適用於帳戶地區設置為 中華民國 之情事。
                    </Typography>

                    <Box sx={{mt: 4}}/>

                    <Typography variant={"body1"}>
                        本服務符合《通訊交易解除權合理例外情事適用準則》第二條第五項之線上服務，因此不適用 7 天鑑賞期之規定。
                    </Typography>

                    <Box sx={{mt: 4}}/>

                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        取消訂閱
                    </Typography>

                    <Box sx={{ml: 4}}>

                        <Typography variant={"body1"}>
                            您隨時可以取消進行中的訂閱，除了以下特定服務除外：
                        </Typography>

                        <ul>
                            <li>於租用時承諾長期租用之服務</li>
                            <li>於訂閱服務時載明無法隨時終止之服務</li>
                            <li>依客戶需求簽訂之合約</li>
                        </ul>
                    </Box>


                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        退費
                    </Typography>

                    <Box sx={{ml: 4}}>
                        <Typography variant={"body1"}>
                            在特定條件下符合退費資格：
                        </Typography>

                        <ul>
                            <li>機房因維護或其他因素造成的服務中斷</li>
                            <li>效能不符合預期，且調查後可歸咎於本公司之情事</li>
                            <li>訂閱服務因故未提供之情況</li>
                        </ul>


                        <Typography variant={"body1"}>
                            如欲退費，請至 Console 確認選擇的資源群組後，於左側功能選項進入 <b>帳務 > 明細</b>
                            ，點選當期月份後，再點選有疑慮之服務，於下方點選 "退費申請" 按鈕，並填寫相關資訊後，將會有專人向您聯繫。
                        </Typography>
                        <Typography variant={"body1"}>
                            如退費申請成功，款項會依照原先於表單中選擇的退款方式進行退款。

                            <ul>
                                <li><b>退回為餘額</b>：立即，無使用期限</li>
                                <li><b>退回至信用卡</b>：約 7~14 個工作天內</li>
                                <li><b>退回至指定銀行帳戶</b>：約 3~5 個工作天內 (該帳戶需經驗證為本人持有)</li>
                                <li><b>至本公司據點領取</b>：立即，需親自前往，並攜帶身分證明文件</li>
                            </ul>
                        </Typography>

                    </Box>

                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        實體設備
                    </Typography>

                    <Box sx={{ml: 4}}>

                        <Typography variant={"body1"}>
                            此條目適用訂閱服務含有實體設備之情事
                        </Typography>

                        <Box sx={{mt: 2}}/>

                        <Typography variant={"body1"}>
                            若訂閱的服務含有實體設備且訂閱時需置於本公司機房
                            （如 <b>計算 > 實體主機</b> 所需之伺服器），
                            於服務停租時會聯繫客戶取回該設備， 且依照性質不同有下列處置方式，其餘未盡事項比照上方規則辦理。
                        </Typography>

                        <ol>
                            <li>自備設備：由於設備為自有，因此上述退費條件 "效能不符合預期" 僅適用於連外線路。</li>
                            <li>本公司代為採購：由於該設備為客製化規格，因此無法退貨/退款。</li>
                        </ol>

                        <Box sx={{mt: 5}}/>

                        <Typography variant={"body1"}>
                            若訂閱的服務需將實體設備置於客戶指定地點
                            （如 <b>智慧生活 > 車牌辨識</b> 的終端設備），
                            則依照性質不同有下列處置方式，其餘未盡事項比照上方規則辦理。
                        </Typography>

                        <ol>
                            <li>自備設備：由於設備為自有，因此上述退費條件 "效能不符合預期" 需經本公司技術人員確認。</li>
                        </ol>

                        <Box sx={{mt: 5}}/>

                        <Typography variant={"body1"}>
                            對於上述所退還之設備，可選擇宅配或親取，租用滿一年（365 天）者免收寄件費。
                        </Typography>
                    </Box>


                    <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                        維修/換貨
                    </Typography>

                    <Box sx={{ml: 4}}>

                        <Typography variant={"body1"}>
                            若訂閱服務的設備損壞， 則依照性質不同有下列處置方式。
                        </Typography>

                        <ol>
                            <li>置於本公司機房的自備設備：若自備的設備損壞，請聯繫本公司進行後續處理。</li>
                            <li>置於本公司機房的代購設備：若客戶操作並無不當，則本公司會代為處理維修或更換事宜。</li>
                            <li>本公司置於客戶指定地點的設備：本公司會派員了解，並依照情況進行維修或更換。</li>
                        </ol>

                        <Box sx={{mt: 5}}/>

                        <Typography variant={"body1"}>
                            對於上述更換/維修設備所產生的費用，會依據實際情況有所不同，先行開立報價單，同意後方可進入下一步。
                        </Typography>
                        <Typography variant={"body1"}>
                            進行更換/維修會於收費後開始進行，並一併開立發票。
                        </Typography>
                    </Box>

                    <Box sx={{mt: 5}}/>

                    <Typography variant={"body2"}>
                        天日科技有限公司<br/>
                        Zentring LTD.<br/>
                        2024/06/28
                    </Typography>
                </Container>
            </div>

        </div>

    </>
}