import React from 'react';
import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select, TextField, Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import axios from "axios";
import Decimal from "decimal.js";

class Network extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {

            creating: {
                ipVersion: "ipv6",
            },
        }

        this.updateCreating.bind(this)
    }


    updateCreating(name, value) {

        let creating = this.state.creating
        creating[name] = value
        this.setState({creating: creating})
    }

    componentDidMount() {

    }

    render() {
        const {t} = this.props;

        //day -> 86400
        //month (30 day) -> 2592000

        //TODO: price
        let price = (new Decimal(this.state.creating.ipVersion === "ipv4" ? "0.001" : "0.00001"))

        price = price.times(86400)
        return (
            <>
                <div style={{width: "100%", maxWidth: 360}}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel>{t('Location')}</FormLabel>
                        <Select displayEmpty fullWidth disabled
                                value={"1"}
                                onChange={(e) => {

                                }}
                        >
                            {/*<Divider textAlign={"left"}>亞洲</Divider>*/}
                            <MenuItem value={"1"}>
                                <div style={{
                                    //512 x 340
                                    display: "inline-block",
                                    backgroundImage: "url(https://account.zentring.net/static/media/TW.dc169005.svg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    borderRadius: 5,
                                    width: 25,
                                    backgroundPositionY: -4.19921875,
                                    height: 16.6015625,
                                    verticalAlign: "middle",
                                    marginRight: 10,
                                }}/>
                                {t('Republic of China')} - {t('Kaohsiung')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div style={{height: 20}}/>

                <div style={{width: "100%", maxWidth: 360}}>
                    <FormControl>
                        <FormLabel>IP 版本</FormLabel>
                        <RadioGroup row value={this.state.creating.ipVersion}
                                    onChange={(e) => {
                                        this.updateCreating("ipVersion", e.target.value)
                                    }}>
                            <FormControlLabel value="ipv4" control={<Radio/>} label="IPv4"/>
                            <FormControlLabel value="ipv6" control={<Radio/>} label="IPv6"/>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div style={{height: 30}}/>

                <Typography
                    variant={"h6"}>{t('Estimated costs')}：NT${(this.props.isInDay ? price : price * 30).toFixed(2)} / {this.props.isInDay ? t('day') : t('month')}</Typography>

                <div style={{height: 30}}/>
                <Button variant={"contained"} color={"primary"} onClick={(e) => {
                    this.props.onItemAdd(this.state.creating, price)
                }}>新增</Button>
            </>
        );
    }
}

export default withTranslation()(Network);