import React from "react";
import {Link} from "react-router-dom";
import logo from "../logo.svg";
import axios from "axios";
import {
    Typography,
    Avatar,
    Button,
    Card,
    CardContent,
    List,
    ListItemAvatar, ListItemText, ListItemButton,
} from "@mui/material";
import {red} from "@mui/material/colors";
import {withStyles} from 'react-jss';
import {withTranslation} from "react-i18next";

const classes = theme => ({
    navBar: {
        background: "gray",
        height: 50,
    },
    link: {
        color: "#fff",
        textDecoration: "none",
        verticalAlign: "middle",
        lineHeight: "50px",
        // display: "inline-block",
        marginLeft: 10,
        marginRight: 10,
        display: "inline-flex",
        alignItems: "center",
        height: 50,
        justifyContent: "center",
    },
    profileImage: {
        width: 30,
        borderRadius: "50%",
    },
    card: {
        position: "fixed",
        zIndex: 9999999,
        right: 10,
        top: 60,
    },
    hidden: {
        display: "none"
    }
});

const logoRatio = 512 / 512;
let isInit = false;

class Top extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isProfileCardOpen: false,
            account: null
        }
    }

    componentDidMount() {

        if (!isInit) {
            window.profileRange = document.getElementById("2qJcr")
            window.profileImage = document.getElementById("x1NTzkx7")
            window.addEventListener("click", (e) => {
                if (!window.profileRange.contains(e.target) && !window.profileImage.contains(e.target)) {
                    this.setState({
                        isProfileCardOpen: false,
                    })
                }
            })
            axios.get('https://account.zentring.net/api/getUser?withInformation', {
                withCredentials: true
            }).then((response) => {
                if (response.data.status) {
                    this.setState({account: response.data.data})
                }
            }).catch(function (error) {
            }).then(function () {
            });
        }
        isInit = true
    }

    render() {
        const {classes} = this.props;
        const {t} = this.props.i18n

        const activeUserImage = this.state.account && this.state.account.users[this.state.account.activeUser] ? this.state.account.users[this.state.account.activeUser].image : "https://account.zentring.net/none.png"
        return (
            <div>
                <div style={{
                    position: "fixed",
                    height: 50,
                    width: "100%",
                    backgroundColor: "white",
                    zIndex: 4999,
                    background: "#4C5373"
                }}>
                    <div style={{
                        height: 50,
                        lineHeight: "50px",
                        width: "100%",
                    }}>
                        <div style={{height: 50, marginLeft: 40, fontSize: 0}}>
                            <Link to={"/"} className={classes.link}>
                                <div style={{
                                    backgroundImage: "url(" + logo + ")",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    display: "inline-block", height: 40 * 0.8, width: 50 * logoRatio * 0.8,
                                    verticalAlign: "middle",
                                }}/>
                                <span style={{marginLeft: 10, fontSize: "initial",}}>Zentring Hosting</span>
                            </Link>
                            <Link to={"/price"} className={classes.link}>
                                <span style={{marginLeft: 10, fontSize: "initial",}}>{t('Pricing')}</span>
                            </Link>
                            <Link to={"/calculator"} className={classes.link}>
                                <span style={{marginLeft: 10, fontSize: "initial",}}>{t('Pricing calculate')}</span>
                            </Link>
                            {/*<Hidden only={['xs']}>*/}
                            {/*    <Link className={classes.link}>123</Link>*/}
                            {/*</Hidden>*/}
                            <div style={{float: "right", height: 50}}>
                                <a className={classes.link} href={"#"} id={"x1NTzkx7"} onClick={(e) => {
                                    this.setState({
                                        isProfileCardOpen: !this.state.isProfileCardOpen,
                                    })
                                    e.preventDefault();
                                    e.stopPropagation();
                                    return false
                                }}>
                                    <img
                                        src={activeUserImage}
                                        alt={"Account image"}
                                        style={{flexDirection: "column",}}
                                        className={classes.profileImage}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={"2qJcr"}
                     className={[classes.card, this.state.isProfileCardOpen ? "" : classes.hidden].join(" ")}>
                    <Card className={classes.root} style={{width: 300}}>
                        {this.state.account && this.state.account.users[this.state.account.activeUser] ? <CardContent>
                            <div style={{display: "inline-flex"}}>
                                <Avatar src={this.state.account.users[this.state.account.activeUser].image}
                                        style={{display: "inline-block", width: 30, height: 30}}/>
                                <span style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    lineHeight: "30px",
                                    marginLeft: 10,
                                }}>{this.state.account.users[this.state.account.activeUser].displayName}</span>
                            </div>
                            <Typography className={classes.pos} color="textSecondary">
                                {this.state.account.activeUser}@zentring.net
                            </Typography>
                            <Typography variant="body2" component="p">
                                <Button component={"a"} href="//account.zentring.net"
                                        style={{color: "mediumpurple"}}>{t('Zentring account')}</Button>
                                <Button onClick={() => {

                                    let form = new FormData();
                                    form.append("account", this.state.account.activeUser);

                                    axios.post('https://account.zentring.net/api/logout', form, {withCredentials: true})
                                        .then((response) => {
                                            if (response.data.status) {
                                                window.location.href = "https://account.zentring.net/v3/?from=6oHhAX&continue=" + encodeURI("https://console.zentring.net" + window.location.pathname)
                                            } else {
                                                console.log(response.data);
                                            }
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        })
                                        .finally(function () {
                                        });
                                }}
                                        style={{color: red[700]}}>{t('Sign out')}</Button>
                            </Typography>
                            <List>
                                {Object.entries(this.state.account.users).filter(([k, v]) => k !== this.state.account.activeUser).map(([username, v]) => {

                                    return <ListItemButton key={username} onClick={(e) => {
                                        if (v.isLogin) {
                                            axios.get('https://account.zentring.net/api/activeUser?username=' + username, {withCredentials: true})
                                                .then((response) => {
                                                    if (response.data.status) {
                                                        window.location.reload()
                                                    } else {
                                                        console.log(response.data);
                                                    }
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                })
                                                .finally(function () {
                                                });
                                        } else {
                                            window.location.href = "https://account.zentring.net/v3/signin?user=" + username + "&continue=" + encodeURIComponent(window.location.href)
                                        }

                                    }}>
                                        <ListItemAvatar>
                                            <Avatar alt={v.displayName}
                                                    src={v.image}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<>
                                                <Typography
                                                    component="span"
                                                    variant="body1"
                                                    color="textPrimary"
                                                >
                                                    {v.displayName}
                                                </Typography>
                                                <Typography sx={{fontStyle: 'italic'}}
                                                            component="span"
                                                            variant="body2"
                                                            color="textPrimary"
                                                >
                                                    {v.isLogin ? "" : t("Logged out")}
                                                </Typography>
                                            </>}
                                            secondary={username + "@zentring.net"}/>
                                    </ListItemButton>
                                })}
                            </List>

                            <Typography variant="body2" component="p">
                                <Button component={"a"} href="//account.zentring.net/v3/signin?from=yVOremK2"
                                        style={{color: "mediumpurple"}}>{t('Add another account')}</Button>
                            </Typography>
                        </CardContent> : <CardContent>
                            <div style={{display: "inline-flex"}}>
                                <Avatar src={"https://account.zentring.net/none.png"}
                                        style={{display: "inline-block", width: 30, height: 30}}/>
                                <span style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    lineHeight: "30px",
                                    marginLeft: 10,
                                }}>{t("Not logged in")}</span>
                            </div>
                            <Typography variant="body2" component="p">
                                <Button component={"a"} href="//account.zentring.net/v3/signin?from=888b2"
                                        style={{color: "mediumpurple"}}>{t('Log into Zentring')}</Button>
                            </Typography>
                        </CardContent>}
                    </Card>
                </div>
                <div style={{height: 50}}/>
            </div>
        );
    }
}

export default withTranslation()(withStyles(classes)(Top));
