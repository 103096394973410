import React from "react";
import {Typography, Container, Grid, Box, Stack} from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TuneIcon from '@mui/icons-material/Tune';
import {withStyles} from 'react-jss';
import {Trans, withTranslation} from "react-i18next";
import {ScrollRestoration} from "react-router-dom";
import CustomDemo from "../Components/CustomDemo";
import {Lightbox} from "react-modal-image";

const classes = theme => ({
    link: {
        // color: "#9199BE",
        textDecoration: "none",
        verticalAlign: "middle",
    },
});

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            popupImage: "",
        }
    }

    render() {
        const {classes} = this.props;
        const {t} = this.props.i18n
        return (<>
                <ScrollRestoration/>
                {
                    this.state.popupImage && (
                        <Lightbox
                            medium={this.state.popupImage}
                            large={this.state.popupImage}
                            // alt="Hello World!"
                            onClose={() => {
                                this.setState({popupImage: ""})
                            }}
                        />
                    )
                }
                <div style={{
                    overflow: "hidden",
                    background: "white",
                }}>
                    <div style={{
                        backgroundImage: "url(/DataCenter.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: 500,
                        position: "relative",
                    }}>
                        <Container>
                            <div style={{position: "relative", height: 500}}>
                                <div style={{
                                    position: "absolute",
                                    transform: "translate(-50%,-50%)",
                                    top: "50%",
                                    left: "50%",
                                    minWidth: "90VW",
                                }}>
                                    <Typography variant={"h1"} align={"center"}
                                                sx={{color: "white", textStroke: "2px black", fontSize: "10VW"}}>
                                        <svg width="1920" height="500" style={{width: "100%", height: "100%"}}
                                             xmlns="http://www.w3.org/2000/svg">
                                            <text x="50%" y="50%" dominantBaseline="middle" fill={"white"}
                                                  style={{fill: "white", stroke: "black", strokeWidth: 2}}
                                                  filter="url(#whiteOutlineEffect)"
                                                  strokeWidth="22" paintOrder="stroke"
                                                  textAnchor="middle">
                                                {t('Zentring Hosting')}
                                            </text>
                                        </svg>
                                    </Typography>
                                </div>
                            </div>


                        </Container>
                    </div>
                    <div>
                        <Container>
                            <Box sx={{mt: 25, mb: 25}}>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-around"}>
                                    <Typography variant={"h4"} align={"center"}>
                                        <Trans
                                            i18nKey="Website? Game server? We've got you covered!"
                                            components={{
                                                b: <br/>
                                            }}
                                        />
                                    </Typography>
                                    <img src={"./favicon.svg"} alt={"screenshot"}
                                         style={{
                                             width: 200,
                                             maxWidth: "80VW",
                                         }}/>
                                </Stack>

                                <Box sx={{mt: 15}}/>

                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-around"}>
                                    {/*<DiscountIcon sx={{fontSize: 200, maxWidth: "80VW",}}/>*/}
                                    {/*<AssessmentIcon sx={{fontSize: 200, maxWidth: "80VW",}}/>*/}
                                    <img src={"/image/budget-cost.svg"} alt={"screenshot"}
                                         style={{
                                             width: 200,
                                             maxWidth: "80VW",
                                         }}/>
                                    <Box>
                                        <Typography variant={"h4"} align={"center"}>
                                            價格比其他國內業者低
                                        </Typography>
                                        <Typography variant={"body1"} align={"center"}>
                                            {/*就算你要搭配 1 核心 CPU 和 2 TB 硬碟這種奇怪的組合，我們也能滿足你*/}
                                            將同規格進行比價，我們幾乎是打到骨折
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Box sx={{mt: 15}}/>

                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant={"h4"} align={"center"}>
                                            Zentring 工程師設計的後台
                                        </Typography>
                                        <Typography variant={"body1"} align={"center"}>
                                            畫面不止現代，就連核心數和記憶體也能自由選擇
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                                            <CustomDemo/>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </div>
                    <div style={{
                        position: "relative", color: "white",
                    }}>
                        <div style={{
                            position: "absolute",
                            background: "#4C5373",
                            width: "300VW",
                            height: 1200,
                            left: "-100VW",
                            transform: "rotate(5deg)",
                        }}>

                            <div style={{
                                background: "mediumpurple",
                                width: "40%",
                                height: 30,
                                position: "absolute",
                                bottom: -30,
                                left: 0,
                            }}>
                            </div>
                        </div>
                        <Container>
                            <Box sx={{position: "relative", pt: 10, mb: 0}}>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}
                                       sx={{height: 500}}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}
                                                   sx={{height: "100%"}}>
                                                <a href={"/image/1.png"} onClick={(event) => {
                                                    event.preventDefault()
                                                    this.setState({popupImage: "/image/1.png"})
                                                }}>
                                                    <img src={"/image/1.png"} alt={"screenshot"}
                                                         style={{
                                                             width: 600,
                                                             maxWidth: "80VW",
                                                         }}/>
                                                </a>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}
                                                   sx={{height: "100%"}}>
                                                <Typography variant={"h4"} align={"center"}>
                                                    <Trans
                                                        i18nKey="Not familiar with the operation? It's easy~"
                                                        components={{
                                                            b: <br/>
                                                        }}
                                                    />
                                                </Typography>
                                                <Typography variant={"body1"} align={"center"}>
                                                    {t('Modern style, user-friendly design.')}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Box>
                            <Box sx={{position: "relative", mt: 0, mb: 50}}>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}
                                       sx={{height: 500}}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}
                                                   sx={{height: "100%"}}>
                                                <Typography variant={"h4"} align={"center"}>
                                                    深色主題
                                                </Typography>
                                                <Typography variant={"body1"} align={"center"}>
                                                    保護雙眼的同時，也避免引來 Bug (light attract bugs)
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}
                                                   sx={{height: "100%"}}>
                                                <a href={"/image/2.png"} onClick={(event) => {
                                                    event.preventDefault()
                                                    this.setState({popupImage: "/image/2.png"})
                                                }}>
                                                    <img src={"/image/2.png"} alt={"screenshot"}
                                                         style={{
                                                             width: 600,
                                                             maxWidth: "80VW",
                                                         }}/>
                                                </a>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Box>
                        </Container>
                    </div>
                    <div style={{position: "relative"}}>
                        <Container>
                            <Box sx={{}}>
                                <Grid container sx={{mt: 20, mb: 25}}>
                                    <Grid item xs={12} md={true}>
                                        <Box textAlign={"center"}>
                                            <Typography variant={"h4"} align={"center"}>
                                                {t('Professional Technical Support')}
                                            </Typography>
                                            <Typography variant={"body1"} align={"center"}>
                                                {t('24/7, Dedicated Staff, Free Reboot Service.')}
                                            </Typography>
                                        </Box>
                                        <Box textAlign={"center"}>
                                            <img src={"/team.svg"} alt={"screenshot"}
                                                 style={{
                                                     width: 200,
                                                     maxWidth: "80VW",
                                                 }}/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={true}>
                                        <Box textAlign={"center"}>
                                            <Typography variant={"h4"} align={"center"}>
                                                高自由度
                                            </Typography>
                                            <Typography variant={"body1"} align={"center"}>
                                                不侷限於方案，你可以自由搭配所需規格
                                            </Typography>
                                        </Box>
                                        <Box textAlign={"center"}>
                                            <Box sx={{
                                                transformOrigin: "center",
                                                "& :hover": {
                                                    transform: "rotate(180deg)",
                                                    transition: "transform 1s ease",
                                                },
                                                "& :not(:hover)": {
                                                    transform: "rotate(0deg)",
                                                    transition: "transform 1s ease",
                                                },
                                            }}>
                                                <TuneIcon sx={{
                                                    fontSize: 200,
                                                    color: "#333",
                                                    width: 200,
                                                    maxWidth: "80VW",
                                                }}/>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={true}>
                                        <Box textAlign={"center"}>
                                            <Typography variant={"h4"} align={"center"}>
                                                全自動化
                                            </Typography>
                                            <Typography variant={"body1"} align={"center"}>
                                                按下按鈕稍候片刻，熱騰騰的伺服器就準備好了
                                            </Typography>
                                        </Box>
                                        <Box textAlign={"center"}>
                                            <img src={"/image/robotic-arm.png"} alt={"screenshot"}
                                                 style={{
                                                     width: 200,
                                                     maxWidth: "80VW",
                                                 }}/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </div>
                    <div style={{position: "relative"}}>
                        <div style={{
                            position: "absolute",
                            background: "#4C5373",
                            width: "300VW",
                            height: 600,
                            left: "-100VW",
                            transform: "rotate(5deg)",
                        }}>
                            <div style={{
                                background: "mediumpurple",
                                width: "40%",
                                height: 30,
                                position: "absolute",
                                top: -30,
                                right: 0,
                            }}>
                            </div>
                        </div>
                        <Container>
                            <div style={{position: "relative", height: 400}}>
                                <div style={{
                                    position: "absolute",
                                    transform: "translate(-50%,-50%)",
                                    top: "50%",
                                    left: "50%",
                                    width: "max-content",
                                    // bottom: "-100%",
                                }}>
                                    <Typography
                                        variant={"h3"} align={"center"}
                                        style={{
                                            maxWidth: "90VW",
                                            color: "white",
                                            margin: 20,
                                        }}>
                                        {t('There to go')}
                                    </Typography>
                                    <a style={{color: "white",}} className={classes.link}
                                       href={"https://console.zentring.net/"}>
                                        <div
                                            style={{
                                                border: "1px solid rgba(255,255,255,.7)",
                                                padding: "10px 100px",
                                                borderRadius: 10,
                                                maxWidth: "90VW",
                                            }}>
                                            <Typography variant={"h4"} align={"center"}
                                                        style={{verticalAlign: "middle",}}>
                                                <KeyboardArrowRightIcon
                                                    fontSize={"large"}
                                                    style={{verticalAlign: "middle",}}/>
                                                {t('Go to Console')}
                                            </Typography>
                                        </div>
                                    </a>
                                    {/*<a style={{color: "white",}} className={classes.link}*/}
                                    {/*   href={"https://console.zentring.net/"}>*/}
                                    {/*    <div style={{*/}
                                    {/*        padding: "10px 100px",*/}
                                    {/*        maxWidth: "90VW",*/}
                                    {/*    }}>*/}
                                    {/*        <Typography variant={"h6"} align={"center"} style={{verticalAlign: "middle",}}>*/}
                                    {/*            或與專人聯繫*/}
                                    {/*        </Typography>*/}
                                    {/*    </div>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(withStyles(classes)(Main));
