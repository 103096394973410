import React, {useEffect} from 'react';
import {
    Routes,
    Route, Navigate, createRoutesFromElements, createBrowserRouter, RouterProvider, Outlet,
} from "react-router-dom";
import Top from "./Components/Top";
import Main from "./Page/Main";
import Footer from "./Components/Footer";
import Calculator from "./Page/Calculator";
import {useTranslation, withTranslation} from "react-i18next";
import {useCookies, withCookies} from "react-cookie";
import Price from "./Page/Price";
import Refund from "./Page/Refund";
import Terms from "./Page/Terms";

function Layout() {

    const [language, setLanguage] = React.useState("")
    const {i18n} = useTranslation();
    const [cookies, setCookie] = useCookies(['overrideLanguage']);


    useEffect(() => {
        getAcceptLanguage()
    }, []);

    const getAcceptLanguage = () => {
        let l = cookies.overrideLanguage
        if (i18n.options.resources[l]) {
            changeLanguage(l)
            return
        }
        let lang = navigator.languages.find((v, k) => {
            return i18n.options.resources[v]
        })
        if (!lang) {
            lang = "en-US"
        }
        changeLanguage(lang)

    }

    const changeLanguage = (languageCode) => {
        i18n.changeLanguage(languageCode)
        if (languageCode !== undefined) {
            if (languageCode !== language && language !== "") {
                setCookie("overrideLanguage", languageCode, {path: "/", maxAge: 86400 * 365})
            }
            setLanguage(languageCode)
            document.documentElement.lang = languageCode
        }
    }
    return <>
        <Top/>
        <Outlet/>
        <Footer language={language} onLanguageChange={(languageCode) => {
            changeLanguage(languageCode)
        }}/>
    </>
}

const router = createBrowserRouter(createRoutesFromElements(<Route element={<Layout/>}>
    <Route path={"/calculator/:id/*"} element={<Calculator/>}/>
    <Route path={"/calculator/"} element={<Navigate to={"/calculator/vm"}/>}/>
    <Route path={"/price"} element={<Price/>}/>
    <Route path={"/refund"} element={<Refund/>}/>
    <Route path={"/terms"} element={<Terms/>}/>
    <Route path={"/"} element={<Main/>}/>
    <Route path={"/*"} element={<Navigate to={"/"}/>}/>
</Route>))

class App extends React.Component {
    constructor(props) {
        super(props);
        this.props = props
        this.state = {
            language: "",
        }
    }

    render() {
        return (
            <div style={{background: "white"}}>
                <RouterProvider router={router}/>
            </div>
        );
    }
}

export default withCookies(withTranslation()(App));
