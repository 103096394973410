import {
    Container,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import {t} from "i18next";
import {ScrollRestoration} from "react-router-dom";

export default function Price() {


    //get language

    const {i18n} = useTranslation();

    useEffect(() => {

    }, []);

    return (
        <>
            <ScrollRestoration/>
            <div style={{overflow: "hidden", background: "white", minHeight: "calc(100VH - 500px)"}}>
                <div style={{position: "relative", background: "#ffffff",}}>
                    <Container>
                        <Typography variant={"h4"} align={"left"} style={{paddingTop: 20}}>
                            {t('Pricing')}
                        </Typography>
                        <Typography variant={"body1"} align={"left"} color={"textSecondary"}>
                            <Trans i18nKey="Prices last updated on">
                                Prices last updated on {"2024/04/17"}
                            </Trans>
                        </Typography>

                        <div style={{height: 10}}/>
                        <a href={"/assets/image/price_" + i18n.language.replace("-", "") + ".png"}>
                            <img src={"/assets/image/price_" + i18n.language.replace("-", "") + ".png"} width={"100%"}
                                 alt={"price"}/>
                        </a>

                        <Typography variant={"body1"} align={"left"} color={"textSecondary"}>
                            點擊即可放大檢視
                        </Typography>
                    </Container>
                </div>

            </div>
        </>
    );
}