import React from "react";
import {Box, Container, Grid, MenuItem, Select} from "@mui/material";
import {withTranslation} from "react-i18next";
import {withRouter} from "../withRouter";
import {Link} from "react-router-dom";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const isHome = this.props.location.pathname === "/";
        const {t} = this.props.i18n;
        return (
            <>
                <Box sx={{position: "absolute", width: "100%", overflow: "hidden", background: "white"}}>

                    <div style={{height: isHome ? 0 : 150}}/>
                    <div style={{position: "relative", height: isHome ? 0 : undefined}}>
                        <div style={{
                            position: "absolute",
                            background: "#4C5373",
                            width: "300VW",
                            height: 1000,
                            left: "-100VW",
                            top: isHome ? -200 : 0,
                            transform: "rotate(5deg)",
                        }}>
                            <div style={{
                                background: "mediumpurple",
                                width: "40%",
                                height: 30,
                                position: "absolute",
                                top: -30,
                                right: 0,
                            }}/>
                        </div>
                        <Container>
                            <div style={{position: "relative", height: 200,}}/>
                        </Container>
                    </div>
                    <div>
                        <div style={{margin: 20, color: "white",}}>
                            <Container maxWidth={"xl"} sx={{mt: 4, textAlign: "right"}}>
                                <Grid container sx={{
                                    mt: 0,
                                    "& *": {
                                        color: "white",
                                        textDecoration: "none",
                                    }
                                }}>
                                    < Grid item sx={{width: 150, display: "inline-block", position: "relative"}}>
                                        <a href={"//support.zentring.net/"}
                                           style={{display: "block", marginBottom: 20}}>
                                            支援
                                        </a>
                                        <a href={"//zentring.net/contact?service=hosting"} style={{display: "block", marginBottom: 20}}>
                                            {t("Contact us")}
                                        </a>
                                    </Grid>
                                    <Grid item sx={{width: 150, display: "inline-block", position: "relative"}}>
                                        <Link to={"/terms"} style={{display: "block", marginBottom: 20}}>
                                            條款與細則
                                        </Link>

                                        <Link to={"/refund"} style={{display: "block", marginBottom: 20}}>
                                            退換貨政策
                                        </Link>
                                    </Grid>
                                    <Grid item xs={true} sx={{
                                        textAlign: "left"
                                    }}>
                                        <Box sx={{
                                            float: "right",
                                            textAlign: "right"
                                        }}>
                                            <Select value={this.props.language} sx={{
                                                color: "white",
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white'
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white'
                                                }
                                            }} onChange={(e) => {
                                                this.props.onLanguageChange(e.target.value)
                                            }}>
                                                <MenuItem value={"en-US"}>
                                                    English (United State)
                                                </MenuItem>
                                                <MenuItem value={"zh-CN"}>
                                                    简体中文 (中国大陸)
                                                </MenuItem>
                                                <MenuItem value={"zh-TW"}>
                                                    正體中文 (台灣)
                                                </MenuItem>
                                            </Select>
                                            <Box sx={{display: "block", position: "relative", mt: 2}}>Copyright
                                                ©{new Date().getFullYear()} Zentring.&nbsp;All rights
                                                reserved.
                                            </Box>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Container>

                        </div>
                    </div>
                </Box>
            </>
        )
            ;
    }
}

export default withRouter(withTranslation()(Footer));
