import React from 'react';
import {
    Button, Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select, TextField, Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import axios from "axios";
import Decimal from "decimal.js";

class Minecraft extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            specs: -443,

            creating: {
                CPU: "i1",
                RAM: 1,

                storageSize: 10,
                isSupport: false,
            },
        }

        this.loadSpec.bind(this)
        this.updateCreating.bind(this)
    }


    updateCreating(name, value) {

        let creating = this.state.creating
        creating[name] = value
        this.setState({creating: creating})
    }

    loadSpec() {
        if (window.isLoadingSpec) {
            return
        }
        window.isLoadingSpec = true
        let formData = new FormData();

        axios.post("https://console.zentring.net/api/vm/listSpecs", formData, {
            withCredentials: true
        }).then(r => {
            this.setState({specs: r.data.data}, () => {
                // console.log(this.state.specs)
            })
        }).finally(() => {
            window.isLoadingSpec = false
        })
    }

    componentDidMount() {
        if (this.state.specs === -443) {
            this.loadSpec()
        }
    }

    render() {
        const {t} = this.props;

        //day -> 86400
        //month (30 day) -> 2592000

        let CPU = new Decimal("0.0000192901");
        let RAM = new Decimal("0.0000192901");
        let SSD = new Decimal("0.000001929");

        let price = (new Decimal("0")).add(CPU.times(this.state.creating.CPU.replace(/[^0-9]/g, '')))
            .add(RAM.times(this.state.creating.RAM))
            .add(SSD.times(this.state.creating.storageSize || 0))
        if (this.state.creating.isSupport) {
            price = price.add(new Decimal("0.00007716"))
        }
        //day -> 86400
        //month (30 day) -> 2592000
        price = price.times(86400)
        return (
            <>
                <div style={{width: "100%", maxWidth: 360}}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel>{t('Location')}</FormLabel>
                        <Select displayEmpty fullWidth disabled
                                value={"1"}
                                onChange={(e) => {

                                }}
                        >
                            {/*<Divider textAlign={"left"}>亞洲</Divider>*/}
                            <MenuItem value={"1"}>
                                <div style={{
                                    //512 x 340
                                    display: "inline-block",
                                    backgroundImage: "url(https://account.zentring.net/static/media/TW.dc169005.svg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    borderRadius: 5,
                                    width: 25,
                                    backgroundPositionY: -4.19921875,
                                    height: 16.6015625,
                                    verticalAlign: "middle",
                                    marginRight: 10,
                                }}/>
                                {t('Republic of China')} - {t('Kaohsiung')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{height: 20}}/>
                <Grid container style={{width: "100%", maxWidth: 360}}>
                    <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                            <FormLabel>CPU</FormLabel>
                            <Select displayEmpty style={{marginRight: 1}}
                                    value={this.state.specs.intel ? this.state.creating.CPU : ""}
                                    onChange={(e) => {
                                        this.updateCreating("CPU", e.target.value)
                                    }}
                            >
                                {
                                    this.state.specs.intel && this.state.specs.intel.map((v, k) => {
                                        return <MenuItem value={"i" + (v.cpu)} key={k}>
                                            {v.cpu} Core
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                            <FormLabel style={{marginLeft: 1}}>RAM</FormLabel>
                            <Select displayEmpty style={{marginLeft: 1}}
                                    value={this.state.specs.RAM ? this.state.creating.RAM : ""}
                                    onChange={(e) => {
                                        this.updateCreating("RAM", e.target.value)
                                    }}
                            >
                                {
                                    this.state.specs.RAM && this.state.specs.RAM.filter((v) => {
                                        let cpu;
                                        if (this.state.creating.CPU[0] === "i") {
                                            cpu = this.state.specs.intel.filter((v) => {
                                                return Number(v.cpu) === Number(this.state.creating.CPU.replace(/[^0-9]/g, ''))
                                            })[0]
                                        } else {

                                            cpu = this.state.specs.amd.filter((v) => {
                                                return Number(v.cpu) === Number(this.state.creating.CPU.replace(/[^0-9]/g, ''))
                                            })[0]
                                        }
                                        return v >= cpu.ramMin && v <= cpu.ramMax
                                    }).map((v, k) => {
                                        return <MenuItem value={v} key={k}>
                                            {v} GB
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>

                <div style={{height: 30}}/>

                <div style={{width: "100%", maxWidth: 360}}>

                    <FormControl fullWidth>
                        <FormLabel>{t('Capacity')}</FormLabel>
                        <TextField variant="standard"
                                   sx={{width: "100%", maxWidth: 360}}
                                   value={this.state.creating.storageSize}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">GB</InputAdornment>,
                                   }}
                                   inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                   onChange={(e) => {
                                       if (isNaN(Number(e.target.value))) {
                                           return
                                       }
                                       this.updateCreating("storageSize", e.target.value)
                                   }}/>
                    </FormControl>

                </div>
                <div style={{height: 30}}/>

                <div style={{width: "100%", maxWidth: 360}}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            sx={{marginLeft: 0}}
                            control={
                                <Checkbox
                                    edge="start"
                                    checked={this.state.creating.isSupport}
                                    tabIndex={-1}
                                    onClick={() => {
                                        this.updateCreating("isSupport", !this.state.creating.isSupport)
                                    }}
                                />
                            }
                            label="需要 24 小時專人客服支援"/>
                    </FormControl>
                </div>
                <div style={{height: 30}}/>


                <Typography
                    variant={"h6"}>{t('Estimated costs')}：NT${(this.props.isInDay ? price : price * 30).toFixed(2)} / {this.props.isInDay ? t('day') : t('month')}</Typography>

                <div style={{height: 30}}/>
                <Button variant={"contained"} color={"primary"} onClick={(e) => {
                    this.props.onItemAdd(this.state.creating, price)
                }}>新增</Button>
            </>
        );
    }
}

export default withTranslation()(Minecraft);