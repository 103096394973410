import {Divider, FormControl, FormLabel, Grid, MenuItem, Select} from "@mui/material";
import React, {useEffect} from "react";
import axios from "axios";

export default function CustomDemo() {
    const [specs, setSpecs] = React.useState({});
    const [creating, setCreating] = React.useState({
        CPU: "i1",
        RAM: 1,
    });

    const updateCreating = (name, value) => {
        setCreating({
            ...creating,
            [name]: value
        })
    }

    useEffect(() => {
        if (window.isLoadingSpec) {
            return
        }
        window.isLoadingSpec = true
        let formData = new FormData();
        axios.post("https://console.zentring.net/api/vm/listSpecs", formData, {
            withCredentials: true
        }).then(r => {
            setSpecs(r.data.data)
        }).finally(() => {
            window.isLoadingSpec = false
        })
    }, []);

    return <>
        <Grid container style={{width: 360}} spacing={2}>
            <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                    <FormLabel>CPU</FormLabel>
                    <Select displayEmpty
                            value={specs.intel ? creating.CPU : ""}
                            onChange={(e) => {
                                updateCreating("CPU", e.target.value)
                            }}
                    >
                        <Divider textAlign={"left"}>Intel</Divider>
                        {
                            specs.intel && specs.intel.map((v, k) => {
                                return <MenuItem value={"i" + (v.cpu)} key={k}>
                                    {v.cpu} Core
                                </MenuItem>
                            })
                        }
                        {/*Not provided yet*/}
                        {/*<Divider textAlign={"left"}>Amd</Divider>
                        {
                            specs.amd && specs.amd.map((v, k) => {
                                return <MenuItem value={"i" + (v.cpu)} key={k}>
                                    {v.cpu} Core
                                </MenuItem>
                            })
                        }*/}
                    </Select>
                </FormControl>

            </Grid>
            <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                    <FormLabel>RAM</FormLabel>
                    <Select displayEmpty
                            value={specs.RAM ? creating.RAM : ""}
                            onChange={(e) => {
                                updateCreating("RAM", e.target.value)
                            }}
                    >
                        {
                            specs.RAM && specs.RAM.filter((v) => {
                                let cpu;
                                if (creating.CPU[0] === "i") {
                                    cpu = specs.intel.filter((v) => {
                                        return Number(v.cpu) === Number(creating.CPU.replace(/[^0-9]/g, ''))
                                    })[0]
                                } else {

                                    cpu = specs.amd.filter((v) => {
                                        return Number(v.cpu) === Number(creating.CPU.replace(/[^0-9]/g, ''))
                                    })[0]
                                }

                                return v >= cpu.ramMin && v <= cpu.ramMax
                            }).map((v, k) => {
                                return <MenuItem value={v} key={k}>
                                    {v} GB
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

            </Grid>
        </Grid>
    </>
}