import React from 'react';
import {
    Grid,
    Container,
    Typography,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Box,
    TableHead,
    Switch, Tabs, Tab, IconButton, Tooltip
} from "@mui/material";
import withStyles from "react-jss";
import {Trans, withTranslation} from "react-i18next";

import StorageIcon from '@mui/icons-material/Storage';
import MinecraftIcon from "../Icon/MinecraftIcon";
import LanIcon from "../Icon/LanIcon";
import HarddiskIcon from "../Icon/HarddiskIcon";
import DatabaseIcon from "../Icon/DatabaseIcon";
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import ContactlessIcon from '@mui/icons-material/Contactless';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import FiveMIcon from "../Icon/FiveMIcon";
import GitlabIcon from "../Icon/GitlabIcon";
import GiteaIcon from "../Icon/GiteaIcon";
import OwnCloudIcon from "../Icon/OwnCloudIcon";
import S3Icon from "../Icon/S3Icon";
import {withRouter} from "../withRouter";
import CloseIcon from '@mui/icons-material/Close';

import VM from "./VM";
import Network from "./Network";
import Storage from "./Storage";
import Object from "./Object";
import Database from "./Database";
import Minecraft from "./Minecraft";
import Decimal from "decimal.js";
import {ScrollRestoration} from "react-router-dom";

const classes = (theme) => ({
    link: {
        color: "#fff",
        textDecoration: "none",
        verticalAlign: "middle",
        lineHeight: "50px",
        // display: "inline-block",
        marginLeft: 10,
        marginRight: 10,
        display: "inline-flex",
        alignItems: "center",
        height: 50,
        justifyContent: "center",
    },
    textField: {
        '& > *': {
            margin: 1,
            width: 200,
        }
    },
    table: {
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }
});

function createPageObject(name, url, icon, component) {
    return {
        name: name,
        url: url,
        icon: icon,
        component: component
    }
}

class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "vm",
            calculating: [],
            showPriceInDay: false
        }
        this.appendCalculating.bind(this)
    }

    appendCalculating = (creating, display, price) => {
        this.setState({
            calculating: [...this.state.calculating, {
                creating: creating,
                display: display,
                price: price
            }]
        })
    }

    componentDidMount() {
    }

    render() {
        const {classes} = this.props;
        const {t} = this.props.i18n

        let price = new Decimal(0)
        this.state.calculating.forEach((item) => {
            price = price.plus(item.price)
        })
        if (!this.state.showPriceInDay) {
            price = price.times(30)
        }
        price = price.toDecimalPlaces(2).toString()


        const page = [
            createPageObject("虛擬機器", "vm", <StorageIcon/>,
                <VM isInDay={this.state.showPriceInDay} onItemAdd={(item, price) => {
                    this.appendCalculating(item, <>
                        VM - {item.storageSize} GB<br/>
                        {Number(item.CPU.replace(/[^0-9]/g, ''))} Core {item.CPU.startsWith("i") ? "Intel" : "AMD"} CPU<br/>
                        {item.RAM} GB RAM
                    </>, price)
                }}/>
            ),
            createPageObject("網路", "net", <LanIcon sx={{marginBottom: "6px"}}/>, <Network
                isInDay={this.state.showPriceInDay} onItemAdd={(item, price) => {
                this.appendCalculating(item, <>
                    {item.ipVersion === "ipv4" ? "IPv4" : "IPv6"}
                </>, price)
            }}/>),
            createPageObject("儲存空間", "storage", <HarddiskIcon sx={{marginBottom: "6px"}}/>, <Storage
                isInDay={this.state.showPriceInDay} onItemAdd={(item, price) => {
                this.appendCalculating(item, <>
                    {item.storageSize} GB {item.storageType === "ssd" ? "SSD" : "HDD"} 硬碟
                </>, price)
            }}/>),
            createPageObject("物件儲存", "object", <S3Icon sx={{marginBottom: "6px"}}/>, <Object
                isInDay={this.state.showPriceInDay} onItemAdd={(item, price) => {
                this.appendCalculating(item, <>
                    {item.storageSize} GB 物件儲存
                </>, price)
            }}/>),
            createPageObject("資料庫", "database", <DatabaseIcon sx={{marginBottom: "6px"}}/>, <Database
                isInDay={this.state.showPriceInDay} onItemAdd={(item, price) => {
                this.appendCalculating(item, <>
                    <span style={{margin: "0 2px"}}>
                    {
                        item.db === "mysql" ? "MySQL" : item.db === "mongodb" ? "MongoDB" : item.db === "postgresql" ? "PostgreSQL" : item.db === "mariadb" ? "MariaDB" : ""
                    }
                    </span> 資料庫 - {item.storageSize} GB
                </>, price)
            }}
            />),
            createPageObject("Minecraft", "minecraft", <MinecraftIcon sx={{marginBottom: "6px"}}/>, <Minecraft
                isInDay={this.state.showPriceInDay} onItemAdd={(item, price) => {
                this.appendCalculating(item, <>
                    受控的 Minecraft 伺服器<br/>
                    {item.storageSize} GB<br/>
                    {Number(item.CPU.replace(/[^0-9]/g, ''))} Core <br/>
                    {item.RAM} GB RAM
                </>, price)
            }}/>),
            createPageObject("郵件", "email", <EmailIcon sx={{marginBottom: "6px"}}/>, <div/>),
            createPageObject("簡訊", "sms", <SmsIcon sx={{marginBottom: "6px"}}/>, <div/>),
            createPageObject("收銀員", "cashier", <ContactlessIcon sx={{marginBottom: "6px"}}/>, <div/>),
            createPageObject("實體主機", "dedicated", <DnsOutlinedIcon sx={{marginBottom: "6px"}}/>, <div/>),
            createPageObject("FiveM", "fivem", <FiveMIcon sx={{marginBottom: "6px"}}/>, <div/>),
            createPageObject("Gitlab", "gitlab", <GitlabIcon sx={{marginBottom: "6px"}}/>, <div/>),
            createPageObject("Gitea", "gitea", <GiteaIcon sx={{marginBottom: "6px"}}/>, <div/>),
            createPageObject("ownCloud", "owncloud", <OwnCloudIcon sx={{marginBottom: "6px"}}/>, <div/>),

        ]
        let calculateItem = page.find((item) => item.url === this.props.match.params.id)
        if (!calculateItem) {
            this.props.navigate("/Pricing/vm")
        }
        return (
            // 310
            <>
                <ScrollRestoration/>
                <div style={{overflow: "hidden", background: "white", minHeight: "calc(100VH - 500px)"}}>
                    <div style={{position: "relative", background: "#ffffff",}}>
                        <Container>
                            <Typography variant={"h4"} align={"left"} style={{paddingTop: 20}}>
                                {t('Price estimation')}
                            </Typography>
                            <Typography variant={"body1"} align={"left"} color={"textSecondary"}>
                                <Trans i18nKey="Prices last updated on">
                                    Prices last updated on {"2024/04/17"}
                                </Trans>
                            </Typography>

                            <div style={{height: 10}}/>

                            <Tabs variant="scrollable" value={this.props.match.params.id} onChange={(e, value) => {
                                this.props.navigate("/calculator/" + value)
                            }} scrollButtons={true} sx={{
                                ".MuiTabs-scrollButtons.Mui-disabled": {
                                    opacity: 0.3,
                                }
                            }}>
                                {page.map((item, index) => {
                                    if (index <= 5) {
                                        return <Tab key={index} icon={item.icon} value={item.url} label={item.name}/>
                                    } else {
                                        return <CloneProps key={index}>
                                            {tabProps => (
                                                <Tooltip title={"尚未支援"}>
                                                    <div>
                                                        <Tab disabled icon={item.icon} value={item.url}
                                                             label={item.name}/>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </CloneProps>
                                    }
                                })}
                            </Tabs>

                            <div style={{height: 10}}/>

                            <Grid container spacing={2} style={{maxWidth: "100VW"}}>
                                <Grid item lg={8} md={6} sm={12} xs={12}>
                                    <Box>
                                        {calculateItem && calculateItem.component}
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} xs={12}>
                                    <div style={{position: "sticky", top: 0, marginRight: 0}}>

                                        <Paper style={{marginTop: 10, padding: 8}}>
                                            <Typography variant={"h6"}>{t('Estimated costs')}</Typography>
                                            <Typography
                                                variant={"subtitle2"}>NT${price} / {this.state.showPriceInDay ? t('day') : t('month')}</Typography>
                                            {/*<Divider/>*/}
                                            <TableContainer>
                                                <Table size={"small"}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>{t('Item')}</TableCell>
                                                            <TableCell>
                                                                {t('Price')} / {this.state.showPriceInDay ? t('day') : t('month')}
                                                            </TableCell>
                                                            <TableCell/>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.calculating.map((item, index) => {
                                                            return <TableRow key={index} className={classes.table}>
                                                                <TableCell component="th" scope="row">
                                                                    {item.display}
                                                                </TableCell>
                                                                <TableCell>NT$<span
                                                                    style={{float: "right"}}>{(this.state.showPriceInDay ? item.price : item.price * 30).toFixed(2)}</span></TableCell>
                                                                <TableCell>
                                                                    <IconButton onClick={(e) => {
                                                                        let calculating = this.state.calculating
                                                                        calculating.splice(index, 1)
                                                                        this.setState({calculating: calculating})
                                                                    }}>
                                                                        <CloseIcon fontSize={"small"}/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        })}
                                                        {this.state.calculating.length < 1 ?
                                                            <TableRow>
                                                                <TableCell colSpan={3}>
                                                                    <Typography variant={"body2"}
                                                                                color={"textSecondary"}
                                                                                textAlign={"center"}>{t('No items')}</Typography>
                                                                </TableCell>
                                                            </TableRow> : ""}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            {/*20*/}
                                            <div style={{height: 20}}/>
                                            <Box style={{textAlign: 'right'}}>
                                            </Box>
                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                <Grid item>{t('Unit: month')}</Grid>
                                                <Grid item>
                                                    <Switch checked={this.state.showPriceInDay} onChange={(e) => {
                                                        this.setState({showPriceInDay: e.target.checked})
                                                    }}/>
                                                </Grid>
                                                <Grid item>{t('day')}</Grid>
                                            </Grid>
                                            <Typography variant={"body2"} color={"textSecondary"}>
                                                {t('The above prices are estimates; actual charges will be based on usage.')}
                                            </Typography>
                                        </Paper>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>

                </div>
            </>
        )
    }

}

function CloneProps(props) {
    const {children, ...other} = props;
    return children(other);
}

export default withRouter(withTranslation()(withStyles(classes, {injectTheme: true})(Calculator)));